<style scoped>
.width-xs {
  width: 180px;
}

.content-head .ivu-form-item {
  margin-bottom: 10px;
}

.couponcss {
  position: absolute;
  top: 20%;
  bottom: 20%;
  left: 5%;
  display: flex;
  align-items: center;
  width: 210px;
}

.redtext {
  font-size: 20px;
  font-weight: 500;
  color: #d0251a;
}

.couponremark {
  display: flex;
  flex-direction: column;
  margin-left: 5%;
}

.couponrule {
  margin-bottom: 8px;
  font-size: 13px;
  font-weight: 400;
  color: #222222;
  word-break: keep-all;
}

.coupontime {
  word-break: keep-all;
  color: #999999;
  font-size: 12px;
  font-weight: 400;
}

.couponuse {
  position: absolute;
  top: 20%;
  bottom: 20%;
  right: 5%;
  display: flex;
  align-items: center;
  font-size: 17px;
  font-weight: 500;
  color: #ffffff;
  word-break: keep-all;
}
</style>
<template>
  <div class="page-main">
    <div class="page-content content-main">
      <div class="content-head">
        <p class="title">消息模板（共{{ pager.total }}条数据）</p>
        <div style="padding-top: 34px">
          <Form
            class="custom-form"
            :model="formData"
            label-position="right"
            :label-width="95"
            inline
          >
            <Row>
              <FormItem label="模板编号">
                <Input
                  placeholder="请输入"
                  class="width-xs"
                  v-model="formData.pfModelCode"
                  clearable
                />
              </FormItem>
              <FormItem label="消息标题">
                <Input
                  placeholder="请输入"
                  class="width-xs"
                  v-model="formData.templateName"
                  clearable
                />
              </FormItem>
              <FormItem label="推送渠道">
                <Select
                  v-model="formData.channelType"
                  clearable
                  class="width-xs"
                >
                  <Option
                    v-for="(item, key) in MESSAGE_CHANNEL_TYPE"
                    :value="item.value"
                    :key="key"
                  >
                    {{ item.label }}</Option
                  >
                </Select>
              </FormItem>
              <FormItem label="状态">
                <Select v-model="formData.status" clearable class="width-xs">
                  <Option
                    v-for="(val, key) in AUTO_EVENT_STATUS"
                    :value="key"
                    :key="key"
                    v-show="val"
                    >{{ val }}</Option
                  >
                </Select>
              </FormItem>
            </Row>
            <Row style="justify-content: flex-end">
              <Button type="primary" @click="queryData" style="margin-left: 5px"
                >查询</Button
              >
              <Button
                type="primary"
                @click="clearFormData"
                style="margin-left: 5px"
                >重置</Button
              >
              <Button type="primary" @click="addData" style="margin-left: 5px"
                >新增</Button
              >
            </Row>
          </Form>
        </div>
      </div>
      <div class="page-content-item">
        <sp-table
          :columns="columns"
          :data="dataList"
          :loading="loading"
          :show-total="false"
          :total="pager.total"
          :page="pager.pageNum"
          :page-size="pager.pageSize"
          @change="changePage"
        >
        </sp-table>
      </div>
    </div>
    <Modal title="审核" v-model="modalForm.show" width="700">
      <Form
        ref="modalForm"
        label-position="right"
        :label-width="130"
        style="margin-top: 20px"
        :model="modalForm"
        :rules="modalRule"
      >
        <FormItem label="确认结果" prop="status">
          <RadioGroup v-model="modalForm.status">
            <Radio label="0">通过</Radio>
            <Radio label="1">驳回</Radio>
          </RadioGroup>
        </FormItem>
        <FormItem
          label="请输入驳回原因"
          v-if="modalForm.status === '1'"
          prop="status"
        >
          <Input
            type="textarea"
            v-model="modalForm.refuseReason"
            maxlength="100"
            show-word-limit
          ></Input>
        </FormItem>
      </Form>
      <div slot="footer">
        <Button type="text" size="large" @click="modalForm.show = false"
          >取消</Button
        >
        <Button type="primary" size="large" @click="submit">确定</Button>
      </div>
    </Modal>
    <Modal title="操作日志" v-model="logDialogDisplay" width="800">
      <div class="page-content-item">
        <Table
          :columns="logCol"
          :data="loglist"
          :loading="logLoading"
          border
        ></Table>
      </div>
      <div slot="footer">
        <Button type="text" size="large" @click="logDialogDisplay = false"
          >取消</Button
        >
      </div>
    </Modal>
  </div>
</template>

<script>
  import { MESSAGE_CHANNEL_TYPE, MESSAGE_CHANNELLINK } from '@/util/enum';
  import { getArrayLabel } from '@/util/util';
  export default {
    data() {
      return {
        linkOptions: ['miniPage', 'otherMiniPage', 'web'],
        modalRule: {
          status: [
            {
              required: true,
              message: '请选择确认结果',
              trigger: 'change',
            },
          ],
          refuseReason: [
            {
              required: true,
              message: '请输入驳回原因',
              trigger: 'blur',
            },
          ],
        },
        modalForm: {
          show: false,
          staus: '',
          refuseReason: '',
        },
        AUTO_EVENT_STATUS: ['', '启用', '禁用', '新建'],
        EVENT_TYPE_TASK: [],
        MESSAGE_CHANNEL_TYPE,
        formData: {
          // 查询数据
          businessType: '',
          channelCode: '',
          channelType: '',
          context: '',
          dept: '',
          id: '',
          pfModelCode: '',
          status: '',
          templateName: '',
        },
        formParams: {}, // 提交查询的参数，避免填写了表单数据但并未提交查询时点击翻页按钮导致误把查询条件带进去
        loadState: false,
        columns: [
          {
            title: '模板编号',
            key: 'pfModelCode',
            minWidth: 80,
            align: 'center',
          },
          {
            title: '消息标题',
            key: 'templateName',
            minWidth: 80,
            align: 'center',
          },
          {
            title: '操作反馈',
            key: 'picUrl',
            minWidth: 80,
            align: 'center',
            render: (h, { row }) => {
              if (!row.picUrl) {
                return '';
              }
              if (row.picUrl && row.picUrl.indexOf('#type=') < 0) {
                // 兼容旧数据//默认小程序
                row.picUrl = '#type=miniPage&url=' + row.picUrl;
              }
              let type = 6; // 无
              if (row.picUrl.indexOf('#type=miniPage') >= 0) {
                type = 2;
              } else if (row.picUrl.indexOf('#type=otherMiniPage') >= 0) {
                type = 4;
              } else if (row.picUrl.indexOf('#type=web') >= 0) {
                type = 5;
              }
              let value = row.picUrl.replace(/#type=\S+&/, '');
              return h('div', [
                h('div', MESSAGE_CHANNELLINK[type].label),
                h('div', value),
              ]);
            },
          },
          {
            title: '可触达渠道',
            key: 'channelType',
            align: 'center',
            minWidth: 80,
            render: (h, { row }) => {
              return h(
                'div',
                this.getArrayLabel(MESSAGE_CHANNEL_TYPE, row.channelType)
              );
            },
          },
          {
            title: '状态',
            key: 'status',
            align: 'center',
            minWidth: 80,
            render: (h, { row }) => {
              return h('div', this.AUTO_EVENT_STATUS[row.status]);
            },
          },
          {
            title: '更新时间',
            minWidth: 80,
            align: 'center',
            render: (h, { row }) => {
              return h('div', row.updateDate || row.createDate);
            },
          },
          {
            title: '创建人',
            align: 'center',
            key: 'createBy',
            minWidth: 80,
          },
          {
            title: '操作',
            width: 200,
            align: 'center',
            render: (h, { row }) => {
              let buttons = [];
              let status = row.status;
              let btnList = {
                edit: h(
                  'Button',
                  {
                    props: {
                      type: 'text',
                    },
                    on: {
                      click: () => {
                        this.editData(row.pfModelCode, row);
                      },
                    },
                  },
                  '编辑'
                ),
                detail: h(
                  'Button',
                  {
                    props: {
                      type: 'text',
                    },
                    on: {
                      click: () => {
                        this.detailData(row.pfModelCode);
                      },
                    },
                  },
                  '查看'
                ),
                enable: h(
                  'Button',
                  {
                    props: {
                      type: 'text',
                    },
                    on: {
                      click: () => {
                        this.updateData(row.pfModelCode, 1);
                      },
                    },
                  },
                  '启用'
                ),
                cancel: h(
                  'Button',
                  {
                    props: {
                      type: 'text',
                    },
                    on: {
                      click: () => {
                        this.updateData(row.pfModelCode, 2);
                      },
                    },
                  },
                  '禁用'
                ),
                log: h(
                  'Button',
                  {
                    props: {
                      type: 'text',
                    },
                    on: {
                      click: () => {
                        this.logData(row.pfModelCode);
                      },
                    },
                  },
                  '日志'
                ),
              };
              // eslint-disable-next-line eqeqeq
              if (status == 3) {
                // 新建
                buttons.push(btnList.edit);
                buttons.push(btnList.enable);
              }
              // eslint-disable-next-line eqeqeq
              if (status == 1) {
                // 启用
                // buttons.push(btnList.edit)
                // eslint-disable-next-line eqeqeq
                if (row.channelType == 'INNM') {
                  // zn
                  buttons.push(btnList.edit);
                }
                buttons.push(btnList.detail);
                buttons.push(btnList.cancel);
              // buttons.push(btnList.check)
              }
              // eslint-disable-next-line eqeqeq
              if (status == 2) {
                // zn sms
                if (
                  // eslint-disable-next-line eqeqeq
                  row.channelType == 'INNM' ||
                  // eslint-disable-next-line eqeqeq
                  row.channelType == 'SMS' ||
                  // eslint-disable-next-line eqeqeq
                  row.pfModelCode == 'MOCWECHAT230721y7u3'
                ) {
                  // zn
                  buttons.push(btnList.edit);
                }
                // buttons.push(btnList.edit)
                buttons.push(btnList.detail);
                buttons.push(btnList.enable);
              }
              return h('div', buttons);
            },
          },
        ],
        dataList: [],
        loading: false, // 表格查询状态
        showloading: false,
        btnLoading: false,
        pager: {
          total: 0, // 总条数
          pageNum: 1, // 当前页码
          pageSize: 10, // 每页条数
        },
        loglist: [],
        logDialogDisplay: false,
        logLoading: false,
        logCol: [
          {
            title: '时间',
            key: 'createTime',
            align: 'center',
          },
          {
            title: '操作人',
            key: 'createdBy',
            align: 'center',
          },
          {
            title: '业务类型',
            key: 'status',
            align: 'center',
          },
          {
            title: '备注',
            key: 'opinion',
            align: 'center',
          },
        ],
      };
    },
    created() {
      this.queryData();
    },
    methods: {
      getArrayLabel,
      /**
       * 日志查看
       */
      logData(unionCode) {
        let data = { unionCode: unionCode };
        this.$axios({
          url: this.$api.message.queryLogList,
          data,
        }).then((res) => {
          this.loglist = res;
          this.logDialogDisplay = true;
        });
      },
      /**
       * 审核确认
       */
      submit() {
        this.$refs.modalForm.validate((valid) => {
          if (valid) {
            let data = {
              type: 1,
              id: this.modalForm.id,
              status: this.modalForm.status,
              refuseReason: this.modalForm.refuseReason,
            };
            this.$axios({
              url: this.$api.message.updateStatus,
              data,
            }).then((res) => {
              this.modalForm.show = false;
              this.handleSuccess();
            });
          }
        });
      },
      /**
       * 禁用任务
       */
      updateData(pfModelCode, status) {
        this.$confirm(
          // eslint-disable-next-line eqeqeq
          `选中模板将被${status == 1 ? '启用' : '禁用'}，请确认是否继续`
        ).then(() => {
          let data = {
            pfModelCode,
            status,
          };
          this.$axios({
            url: this.$api.message.updateStatus,
            data,
          }).then((res) => {
            this.handleSuccess();
          });
        });
      },
      /**
       * 操作成功
       */
      handleSuccess() {
        this.notice({
          type: 'success',
          desc: '操作成功',
        });
        this.getData();
      },
      /**
       * 获取数据
       */
      getData() {
        let data = JSON.parse(JSON.stringify(this.formParams));
        data.pageNum = this.pager.pageNum;
        data.pageSize = this.pager.pageSize;
        if (data.status) {
          data.status = [data.status];
        } else {
          delete data.status;
        }
        this.loading = true;
        this.$axios({
          url: this.$api.message.getModelList,
          data,
          complete: () => {
            this.loading = false;
          }
        }).then((data) => {
          this.dataList = data.list || [];
          this.pager.total = Number(data.total) || 0;
        });
      },
      /**
       * 查询数据
       */
      queryData() {
        this.formParams = this.formData;
        this.pager.pageNum = 1;
        this.getData();
      },
      /**
       * 切换页码或每条页数
       */
      changePage(pager) {
        this.pager.pageNum = pager.page;
        this.pager.pageSize = pager.pageSize;
        this.getData();
      },
      /**
       * 重置查询条件
       */
      clearFormData() {
        let tmpformData = {
          businessType: '',
          channelCode: '',
          channelType: '',
          context: '',
          dept: '',
          id: '',
          pfModelCode: '',
          status: '',
          templateName: '',
        };
        this.formData = tmpformData;
      },
      /**
       * 新增数据
       */
      addData() {
        this.$router.push({
          name: 'messageTemplateAdd',
        });
      },
      detailData(id) {
        this.$router.push({
          name: 'messageTemplateDetail',
          query: {
            pfModelCode: id,
          },
        });
      },
      /**
       * 编辑数据
       */
      editData(id, row) {
        // eslint-disable-next-line eqeqeq
        if (row.pfModelCode == 'MOCWECHAT230721y7u3' && row.status == 2) {
          this.$router.push({
            name: 'messageTemplateEdit',
            query: {
              pfModelCode: id,
              type: 'urledit', // 禁用下的非mod,支持编辑触达地址
            },
          });
          return;
        }
        this.$router.push({
          name: 'messageTemplateEdit',
          query: {
            pfModelCode: id,
          },
        });
      },
    },
  };
</script>
